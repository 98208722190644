import TimeAgo from 'javascript-time-ago';
import CryptoJS from 'crypto-js';
import io from 'socket.io-client';

export const Global = {
  socket: io(process.env.REACT_APP_SOCKET_URL, {
    transports: ['polling'],
  }),
  urlParams: window.location.href.split('?')[1],
  createSingleNewsFeedObj(item) {
    let obj = {};
    var timeAgo = new TimeAgo('en-US');
    obj.id = item._id;

    // newsFeedClientId
    if (item.newsFeedClientId) {
      obj.newsFeedClientId = item.newsFeedClientId;
    }

    // title
    if (item.title && item.title.length > 0) {
      obj.title = item.title;
    }

    if (item.is_ready_to_send_push_notification) {
      obj.is_ready_to_send_push_notification =
        item.is_ready_to_send_push_notification;
    }

    // description
    if (item.description && item.description.length > 0) {
      obj.content = item.description;
    }

    /* Sharing */
    if (item.hasOwnProperty('isSharedWithAdmin')) {
      if (item.isSharedWithAdmin) {
        obj.isSharedWithAdmin = true;
      }
    }

    // isSharedWithAll
    if (item.hasOwnProperty('isSharedWithAll')) {
      if (item.isSharedWithAll) {
        obj.isSharedWithAll = true;
      }
    }

    // isSharedWithLearner
    if (item.hasOwnProperty('isSharedWithLearner')) {
      if (item.isSharedWithLearner) {
        obj.isSharedWithLearner = true;
      }
    }

    // onlySharedWith
    if (item.hasOwnProperty('onlySharedWith')) {
      if (item.onlySharedWith) {
        obj.onlySharedWith = item.onlySharedWith;
      }
    }

    // creatorId
    obj.creatorId = item.creatorId ? item.creatorId : null;

    // updatedAt
    obj.updatedAt = timeAgo.format(new Date(item.createdAt));

    // galleryType
    obj.galleryType = 'tile';

    // gallery
    obj.gallery = [];

    obj.combined = [];

    // attachments
    obj.attachments = [];

    // imgsVideos
    let imgsVideos = [];

    // Filter Images and Videos from media array
    if (item.medias) {
      item.medias.forEach((item) => {
        try {
          // If Image Url is define
          if (item.imageUrl) {
            imgsVideos.push(item);
          }

          // If Video URL
          if (item.videoUrl) {
            imgsVideos.push(item);
          }
        } catch (err) {
          console.log(err);
        }
      });
    }

    /* Defile Gallery Type : tile/single portait/vertical tile */
    try {
      if (imgsVideos.length > 0) {
        if (
          imgsVideos[0].orientation === 'square' ||
          imgsVideos[0].orientation === 'portrait'
        ) {
          if (imgsVideos.length === 1) {
            obj.galleryType = 'single portait';
          } else {
            obj.galleryType = 'vertical tile';
          }
        } else {
          obj.galleryType = 'tile';
        }
      }
    } catch (err) {
      console.log(err);
    }

    // If it have orientation that is image otherwise video
    try {
      if (imgsVideos.length > 0) {
        obj.gallery = imgsVideos.map((item) => {
          let fileObj = {};
          fileObj.id = item._id;
          fileObj.type = item.videoThumbnailUrl ? 'video' : 'image';
          fileObj.videoThumbnailUrl = item.videoThumbnailUrl;
          fileObj.imageThumbnailUrl = item.imageThumbnailUrl;
          fileObj.imageUrl = item.imageUrl;
          fileObj.videoUrl = item.videoUrl;
          fileObj.src = item.videoUrl ? item.videoUrl : item.imageUrl;
          fileObj.width = item.width;
          fileObj.height = item.height;

          return fileObj;
        });
      }
    } catch (err) {
      console.log(err);
    }

    // Filter Attachment files List
    if (item.medias) {
      try {
        let fileList = [];
        item.medias.forEach((item) => {
          try {
            // If Image Url is define
            if (item.attachmentUrl) {
              fileList.push({
                ...item,
                type: 'attachment',
              });
            }
          } catch (err) {
            console.log(err);
          }
        });
        obj.attachments = fileList.map(({ _id, ...rest }) => ({
          ...rest,
          id: _id,
        }));
      } catch (err) {
        console.log(err);
      }
    }

    if (item.media) {
      obj.combined = [...obj.gallery, ...obj.attachments];
    }

    return obj;
  },

  createNewsFeedObj(item) {
    var timeAgo = new TimeAgo('en-US');
    let obj = {};
    obj.id = item._id;

    // Initialize ad object
    let adObj = {};

    // Check if advertisement data exists and populate ad object
    if (item.ad) {
      adObj = {
        adPostUrl: item.ad.adPostUrl || null,
        adPostUrlDimensions: item.ad.adPostUrlDimensions || null,
        adThumbUrl: item.ad.adThumbUrl || null,
        adThumbUrlDimensions: item.ad.adThumbUrlDimensions || null,
        mobileThumbUrl: item.ad.mobileThumbUrl || null,
        mobileThumbnailDimensions: item.ad.mobileThumbnailDimensions || null,
        ageGroup: item.ad.ageGroup || null,
        businessId: item.ad.businessId || null,
        businessUserId: item.ad.businessUserId || null,
        club: item.ad.club || null,
        createdAt: item.ad.createdAt || null,
        durationTime: item.ad.durationTime || null,
        fileType: item.ad.fileType || null,
        fromDate: item.ad.fromDate || null,
        platforms: item.ad.platforms || null,
        title: item.ad.title || null,
        toDate: item.ad.toDate || null,
        updatedAt: item.ad.updatedAt || null,
        _id: item.ad._id || null,
        embedLink: item.ad.embedLink || null,
      };
    } else {
      /* News Feed Create */
      /* Sharing */
      obj.isSharedWithAdmin = item.hasOwnProperty('isSharedWithAdmin')
        ? item.isSharedWithAdmin
        : false;
      obj.isSharedWithAll = item.hasOwnProperty('isSharedWithAll')
        ? item.isSharedWithAll
        : false;
      obj.pinnedBy = item.hasOwnProperty('pinnedBy') ? item.pinnedBy : null;
      obj.isSharedWithLearner = item.hasOwnProperty('isSharedWithLearner')
        ? item.isSharedWithLearner
        : false;
      obj.isPinned = item?.isPinned ? item.isPinned : false;
      obj.onlySharedWith = item.hasOwnProperty('onlySharedWith')
        ? item.onlySharedWith
        : null;

      if (item.newsFeed.hasOwnProperty('creator')) {
        if (item.newsFeed.creator.profilePic) {
          obj.img = item.newsFeed.creator.profilePic || '/img/logo.png';
        }
        if (item.newsFeed.creator.org) {
          obj.from = item.newsFeed.creator.org;
        }
        if (item.newsFeed.creator.name) {
          obj.creatorName = item.newsFeed.creator.name;
        }
      }

      obj.title = item.newsFeed.title || null;
      obj.content = item.newsFeed.description || null;
      obj.is_ready_to_send_push_notification =
        item.newsFeed.is_ready_to_send_push_notification || false;
      obj.publishAt = item.publishAt || null;
      obj.createdAt =
        item.createdAt !== undefined
          ? timeAgo.format(new Date(item.createdAt))
          : null;
      obj.publishedAt =
        item.publishedAt !== undefined
          ? timeAgo.format(new Date(item.publishedAt))
          : null;
      obj.updatedAt = timeAgo.format(new Date(item.updatedAt));
      obj.galleryType = 'tile';
      obj.gallery = [];
      obj.attachments = [];
      obj.combined = [];

      obj.newsFeedID = item.newsFeed._id;
      obj.medias = item.newsFeed.medias;
      obj.comments = item.comments;
      obj.totalComments = item.totalComments;
      obj.totalLikes = item.totalLikes;
      obj.likedByUser = item.likedByUser;
      obj.poll = item.newsFeed.poll;
      obj.isPollVoted = item.isPollVoted;
      obj.isCommentable = item.isCommentable;
      obj.creatorId = item.newsFeed.creatorId;

      let imgsVideos = [];

      // Filter Images and Videos from media array
      item.newsFeed.medias.forEach((media) => {
        try {
          if (media.imageUrl || media.videoUrl) {
            imgsVideos.push(media);
          }
        } catch (err) {
          console.log(err);
        }
      });

      /* Define Gallery Type: tile/single portait/vertical tile */
      try {
        if (imgsVideos.length > 0) {
          if (
            imgsVideos[0].orientation === 'square' ||
            imgsVideos[0].orientation === 'portrait'
          ) {
            obj.galleryType =
              imgsVideos.length === 1 ? 'single portait' : 'vertical tile';
          } else {
            obj.galleryType = 'tile';
          }
        }
      } catch (err) {
        console.log(err);
      }

      // If it has orientation that is image otherwise video
      try {
        if (imgsVideos.length > 0) {
          obj.gallery = imgsVideos.map((media) => {
            let fileObj = {};
            fileObj.id = media._id;
            fileObj.type = media.videoThumbnailUrl ? 'video' : 'image';
            fileObj.videoThumbnailUrl = media.videoThumbnailUrl;
            fileObj.imageThumbnailUrl = media.imageThumbnailUrl;
            fileObj.imageUrl = media.imageUrl;
            fileObj.videoUrl = media.videoUrl;
            fileObj.src = media.videoUrl ? media.videoUrl : media.imageUrl;
            fileObj.width = media.width;
            fileObj.height = media.height;
            return fileObj;
          });
        }
      } catch (err) {
        console.log(err);
      }

      // Filter Attachment files List
      try {
        let fileList = [];
        item.newsFeed.medias.forEach((media) => {
          try {
            if (media.attachmentUrl) {
              fileList.push({
                ...media,
                type: 'attachment',
              });
            }
          } catch (err) {
            console.log(err);
          }
        });

        obj.attachments = fileList.map(({ _id, ...rest }) => ({
          ...rest,
          id: _id,
        }));
      } catch (err) {
        console.log('Attachment List:', err);
      }

      try {
        obj.combined = [...obj.gallery, ...obj.attachments];
      } catch (err) {
        console.log(err);
      }
    }

    // Only add adObj to obj if it's not empty
    if (Object.keys(adObj).length > 0) {
      obj.ad = adObj;
    }

    return obj;
  },

  decryptToken: (cipherText) => {
    let bytes = CryptoJS.AES.decrypt(
      cipherText,
      process.env.REACT_APP_BACKEND_DECRYPTION_KEY,
    );
    return bytes.toString(CryptoJS.enc.Utf8);
  },

  user: () => {
    try {
      let split = window.location.href.split('adminToken=');
      let adminToken = split[1];

      return Global.decryptToken(adminToken);
    } catch (error) {
      console.log(error);
    }
  },
  userInfo: () => {
    try {
      let split = window.location.href.split('userInfo=');
      let rawData = Global.decryptToken(split[1]).split(
        process.env.REACT_APP_BACKEND_DECRYPTION_KEY,
      );

      let obj = {
        name: rawData[0],
        organisationName: rawData[1],
        profilePic: rawData[2],
      };

      return obj;
    } catch (error) {
      console.log(error);
    }
  },
  randomID: () => {
    try {
      return Math.floor(Math.random() * (99999 - 1 + 1)) + 1;
    } catch (error) {
      console.log(error);
    }
  },
  getCurrentDate: () => {
    try {
      return new Date();
    } catch (error) {
      console.log(error);
    }
  },
  getCurrentUTCDate: () => {
    try {
      let newDate = new Date();
      let da = newDate;
      let localMinute = da.getMinutes();
      let utcMinute = da.getUTCMinutes();

      newDate = new Date(newDate.getTime());
      newDate.setMinutes(Math.abs((localMinute - utcMinute + 30) % 60));
      newDate.setSeconds(0);

      return newDate;
    } catch (error) {
      console.log(error);
    }
  },
};
