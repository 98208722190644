import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

class CheckForm extends Component {
  static defaultProps = {
    className: '',
  };

  render() {
    const {
      className,
      id,
      name,
      label,
      onChange,
      checked,
      type,
      isInvalid,
      ...restProps
    } = this.props;

    return (
      <FormGroup className={className}>
        <div className="form-check">
          <Input
            type={type || 'checkbox'}
            id={id || name}
            name={name}
            checked={checked}
            onChange={onChange}
            {...restProps}
            className="form-check-input"
          />
          <Label for={id || name} className="form-check-label">
            {label}
          </Label>
          {isInvalid && (
            <FormFeedback valid={false}>{label} is required</FormFeedback>
          )}
        </div>
      </FormGroup>
    );
  }
}

CheckForm.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  isInvalid: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['checkbox', 'radio']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default CheckForm;
